<template>
<div class="">
    <div class="bottom-navbar" :class="{ 'navbar--hidden': !showNavbar }">
        <b-container>
            <b-row>
                <b-col md="12">
                    <b-nav justified>
                        <b-nav-item active to="/index">
                            <div class="">
                                <feather-icon size="24" icon="HomeIcon" />
                                <p>Home</p>
                            </div>
                        </b-nav-item>
                        <b-nav-item active to="/agents">
                        <div>
                        <feather-icon size="24" icon="CompassIcon" />
                        <p>Agents</p>
                        </div>
                        </b-nav-item>
                        <!-- <b-nav-item class="nav-item-chat" to="/create" >
                            <feather-icon size="28" icon="FeatherIcon" />
                        </b-nav-item> -->
                        <div class="nav-item-chat create-highlight" @click="navigateToMyAgents">
  <feather-icon size="28" icon="PlayIcon" />
</div>
                        
                        <!-- <b-dropdown center variant="link" no-caret>
  <template #button-content>
    <div class="nav-item-chat create-highlight">
      <feather-icon size="28" icon="PlayIcon" />
    </div>
  </template>
  <b-dropdown-item to="/agent/memes">
    <feather-icon size="18" icon="SmileIcon" style="margin-right: 3px;" /> 
     Create Memes
  </b-dropdown-item>
  <b-dropdown-item to="/agent/quotes">
    <feather-icon size="18" icon="SunIcon" style="margin-right: 3px;"  /> 
     Create Quotes
  </b-dropdown-item>
  <b-dropdown-item to="/agent/images">
    <feather-icon size="18" icon="ImageIcon" style="margin-right: 3px;"  /> 
     Create Images
  </b-dropdown-item>
</b-dropdown> -->
                        <b-nav-item active to="/explore">
                            <div class="">
                                <feather-icon size="24" icon="StarIcon" />
                                <p class="">Featured</p>
                            </div>
                        </b-nav-item>
                        <b-nav-item @click='navigateToLikePage()'>
                            <div class="">
                                <feather-icon size="24" icon="HeartIcon" />
                                <p class="">Favourites</p>
                            </div>
                        </b-nav-item>

                    

                           <!-- <b-nav-item @click='initializeChat'>
                            <div class="">
                                <feather-icon size="24" icon="MessageCircleIcon" />
                                <p>Assistant</p>
                            </div>
                        </b-nav-item> -->
                        
                        <!-- <b-nav-item @click='initializeChat'>
                            <div class="">
                                <feather-icon size="24" icon="MessageCircleIcon" />
                                <p>Assistant</p>
                            </div>
                        </b-nav-item> -->
                
                        <!-- <b-nav-item v-b-toggle.chatgpt-sidebar>
                            <div class="">
                                <feather-icon size="24" icon="MessageCircleIcon" />
                                <p class="small m-0 hidden-xs-360px">Products</p> 
                            </div>
                        </b-nav-item> -->

                        <!-- <b-nav-item to="/events">
    <div class="">
      <feather-icon size="24" icon="CalendarIcon" />
      <p class="small m-0 hidden-xs-360px">Events</p>
    </div>
      </b-nav-item> -->
                    </b-nav>
                </b-col>
            </b-row>
        </b-container>
    </div>

    <!-- <chat-gpt /> -->

</div>
</template>

<script>
import {
    BNav,
    BNavItem,
    BButton,
    BContainer,
    BRow,
    BCol,
    BBadge,
    BLink,
    BImg,
    VBToggle,
    BTooltip
} from 'bootstrap-vue'
import ChatGpt from '@/views/Website/support/ChatGpt.vue'

export default {
    data() {
        return {
            showNavbar: true,
            lastScrollPosition: 0,
            user_id: ''
        }
    },

    components: {
        BContainer,
        BNav,
        BNavItem,
        BRow,
        BCol,
        BButton,
        BBadge,
        BLink,
        BImg,
        ChatGpt,
    },

    directives: {
        'b-toggle': VBToggle,
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {

        initializeChat() {
        this.$store.commit("app/SET_INITIALIZE_CHAT", true);
    },
        navigateToLikePage() {
            const userData = localStorage.getItem("userData")
            if (userData == null || undefined) {
                this.$bvModal.show("modal-login");
                return false;
            } else {
                this.$router.push(
                    '/collections'
                );
            }
        },
        navigateToMyAgents() {
            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
            this.$bvModal.show("modal-login");
            return false;
            } else {
            this.$router.push(
                '/myagents'
            );
            }
            },
        navigateToExplore() {
            const userData = localStorage.getItem("userData")
            if (userData == null || undefined) {
                this.$bvModal.show("modal-login");
                return false;
            } else {
                this.$router.push(
                  "/explore"
                );
            }
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 54) {
                return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
        }
    }

}
</script>

<style>
* {
    box-sizing: border-box;
}

.bottom-navbar {
    height: 60px;
    /* width: 100vw; */
    bottom: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #eee;
    position: fixed;
    /* box-shadow: 0 2px 15px rgba(71, 120, 120, 0.5); */
    transform: translate3d(0, 0, 0);
    transition: 0.1s all ease-out;
    z-index: 11;
}

.bottom-navbar.navbar--hidden {
    box-shadow: none;
    transform: translate3d(0, 100%, 0);
}

.nav-justified .nav-item {
    text-align: center;
    line-height: 2;
    align-self: center;
    margin-top: 8px;
}

.bottom-navbar .nav-link {
    color: #212121;
}

.bottom-navbar .router-link-exact-active {
    color: #dc123e;
}

/* .nav-item-chat {
    align-self: center;
    margin-bottom: 25px;
} */

.nav-item-chat {
  border: 2px solid #dc123e !important;
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
  display: flex; /* Use flex for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  transition: all 0.2s ease-in-out;
}
.nav-item-chat.create-highlight {
  /* margin-top: -28px !important;  */
  /* padding: 10px;  */
  /* margin-left: -10px; */
  /* margin-right: -10px; */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for highlighting */
  background-color: #f9f9f9; /* Optional: Different background color for emphasis */
}

.b-dropdown .nav-item-chat {
  border: 2px solid #dc123e !important; 
}

.nav-item-chat .nav-link {
    border: 2px solid #dc123e !important;
    width: 46px !important;
    height: 46px !important;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    text-align: center;
    transition: all .2s ease-in-out;
}

.nav-item-chat .nav-link svg {
    color: #dc123e;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}



@media (max-width: 991.98px) {}

@media (max-width: 360px) {
    .hidden-xs-360px {
        display: none !important;
    }

    .nav-justified .nav-item {
        /* line-height: 3.5; */
        align-self: center;
        margin-top: 8px;
    }
}
</style>
