<template>
  <b-modal
    id="modal-login"
    cancel-variant="outline-secondary"
    title=""
    hide-footer
    ref="modalLogin"
    @hidden="resetForm"
  >
    <div class="login-signup px-md-2 py-1">
      <div class="mb-2">
        <!-- Adjust the title based on the flow -->
        <h4 v-if="isSignup && showAdditionalFields">Sign Up</h4>
        <h4 v-else-if="!isSignup && showPasswordField">Log in</h4>
        <h4 v-else>Log In/Sign Up</h4>

        <p v-if="isSignup && showAdditionalFields">Please sign up to create an account</p>
        <p v-else-if="!isSignup && showPasswordField">Please log in to your account</p>
        <p v-else>Please log in to your account.</p>
      </div>

      <!-- Social Login Buttons -->
      <div class="social-login-buttons">
      <!-- Google Sign-In Button -->
<b-button variant="outline-primary" block class="mb-2 d-flex align-items-center justify-content-center" @click="socialSignIn('google')"
style=" color: #ffffff; border-color: #ff5733; padding: 4px 5px; margin: 2px 0;">
  <img :src="require('@/assets/images/icons/google.svg')" alt="Google Icon" width="40" class="mr-2" />
  Sign in with Google
</b-button>

<b-button variant="outline-primary" block class="mb-2 d-flex align-items-center justify-content-center" @click="socialSignIn('twitter')"
style=" color: #ffffff; border-color: #ff5733; padding: 4px 5px; margin: 2px 0;">
  <img :src="require('@/assets/images/icons/x.png')" alt="Twitter Icon" width="40" class="mr-2" />
  Sign in with Twitter/X
</b-button>

    <!-- Apple Sign-In Button -->
<!-- <b-button variant="dark" block class="mb-2 d-flex align-items-center justify-content-center" @click="socialSignIn('apple')">
  <i class="fab fa-apple mr-2"></i> 
  Sign in with Apple
</b-button> -->
      </div>

      <!-- OR Divider -->
      <div class="text-center mb-2">
        <h5> OR </h5>
      </div>

      <!-- Step 1: Email Check Form -->
      <b-form v-if="!showPasswordField && !showAdditionalFields" @submit.prevent="checkUserExists">
        <!-- Email Input -->
        <b-row>
          <b-col cols="12">
            <b-form-group label="Email Address">
              <b-form-input
                placeholder="Email"
                v-model="email"
                required
                style="  border-color: Black; padding-top: -10px;"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Check User Button -->
        <b-button variant="primary" type="submit" block class="mt-2">
          Log In
        </b-button>
      </b-form>

      <!-- Step 2: Handle Login/Signup Form -->
      <b-form v-if="showPasswordField || showAdditionalFields" @submit.prevent="handleAuth">
        <!-- Password Input (For login and signup after email check) -->
        <b-row v-if="showPasswordField">
          <b-col md="12">
            <b-form-group label="Password">
              <b-form-input
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                placeholder="Enter your password"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Additional Fields for Signup -->
        <b-row v-if="isSignup && showAdditionalFields">
          <b-col md="12">
            <b-form-group label="Confirm Password">
              <b-form-input
                type="password"
                v-model="confirmPassword"
                placeholder="Confirm your password"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="User Name">
              <b-form-input
                v-model="userName"
                placeholder="Enter your user name"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Submit Button for Login/Signup -->
        <b-button variant="primary" type="submit" block class="mt-2">
          {{ isSignup ? 'Sign Up' : 'Log in' }}
        </b-button>
      </b-form>

           <!-- Agreement Statement -->
           <div class="agreement mt-3 text-center">
        <small>
          By signing up, you agree to our
          <router-link to="/termsandconditions">Terms and Conditions</router-link> and
          <router-link to="/privacy-policy">Privacy Policy</router-link>.
        </small>
      </div>
    </div>
  </b-modal>
</template>


<script>
import { auth } from '@/firebase'; // Firebase setup
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  TwitterAuthProvider,
  OAuthProvider, // For Apple Sign-In
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      userName: '',
      isSignup: false,
      showPasswordField: false,
      showAdditionalFields: false,
      socialProviderUsed: '', // Track the social provider used
    };
  },
  methods: {
    // Social Sign-In Handler
    async socialSignIn(providerName) {
  let provider;
  switch (providerName) {
    case 'google':
      provider = new GoogleAuthProvider();
      break;
    case 'twitter':
      provider = new TwitterAuthProvider();
      break;
    case 'apple':
      provider = new OAuthProvider('apple.com');
      break;
    default:
      console.error('Unsupported provider');
      return;
  }

  this.socialProviderUsed = providerName; // Track the provider used

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Check if the email is available in the primary user object
    let email = user.email;

    // If the email is not directly in the user object, try checking the provider data
    if (!email && user.providerData) {
      // Look for the email in the providerData array for Twitter
      const twitterProvider = user.providerData.find(p => p.providerId === 'twitter.com');
      if (twitterProvider && twitterProvider.email) {
        email = twitterProvider.email;
      }
    }

    if (!email) {
      // If email is still missing, handle this case, such as by prompting the user
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Twitter did not provide an email. Please enter an email address.',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });

      // Show a form/modal to let the user input their email manually
      return;
    }

    const userData = {
      userId: user.uid,
      email: email,
      userName: user.displayName || 'User',
      photoURL: user.photoURL || 'default_photo_url',
      userRole: 'user',
      account: 'free',
      status: 'active',
      plan: 'starter',
      loginMethod: providerName, // Save the login method
    };

    // Check if user exists in the database
    await this.checkIfUserExistsInDatabase(userData);
  } catch (error) {
    console.error('Error during social sign-in:', error);
    this.handleAuthError(error);
  }
},

    // Check if user exists in the database
    async checkIfUserExistsInDatabase(userData) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}checkUserExists`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: userData.email }),
        });
        const data = await response.json();

        if (data.success) {
          if (data.exists) {
            if (data.loginMethod && data.loginMethod !== userData.loginMethod) {
              // User exists but with a different login method
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `You registered with ${data.loginMethod}. Please sign in using ${data.loginMethod}.`,
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              });
              // Optionally, sign out the user from Firebase if necessary
              await signOut(auth);
              return; // Stop further execution
            }
            // User exists and login methods match, fetch user data
            await this.fetchUserFromDB(userData.userId);
          } else {
            // User does not exist in your database, handle user creation
            await this.handleUserNotFound(userData);
          }
        } else {
          // Handle failure response from the server
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message || 'Error checking user existence. Please try again later.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error checking user existence:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error checking user existence. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Fetch the user data from Cosmos DB
    async fetchUserFromDB(userId) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getUser/${userId}`);
        const data = await response.json();

        if (data.success) {
          this.handleSuccessfulAuth(data.data);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User data not found in the database.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error fetching user from DB:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Handle cases where user is not found in the database
    async handleUserNotFound(userData) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/saveUser`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(userData),
        });
        const data = await response.json();

        if (data.success) {
          this.handleSuccessfulAuth(data.data);
        } else if (data.errorCode === 'DUPLICATE_ERROR') {
          // console.log('Duplicate user found, fetching user data...');
          await this.fetchUserFromDB(userData.userId);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message || 'Error saving user to database.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error saving user to DB:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving user to database. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Email/password Signup and Sign-In Handler
    // Step 1: Check if the user exists
    async checkUserExists() {
      // console.log('Checking if user exists via server for email:', this.email);
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}checkUserExists`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: this.email }),
        });
        const data = await response.json();
        if (data.success) {
          if (data.exists) {
            // Check if the existing user has a different login method
            if (data.loginMethod && data.loginMethod !== 'email') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `You registered with ${data.loginMethod}. Please sign in using ${data.loginMethod}.`,
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              });
              return; // Stop further execution
            }

            // console.log('User exists, setting up for login.');
            this.showPasswordField = true;
            this.isSignup = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please enter your password to log in.',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          } else {
            // console.log('User does not exist, setting up for signup.');
            this.showPasswordField = true;
            this.isSignup = true;
            this.showAdditionalFields = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email not registered. Please sign up.',
                icon: 'InfoIcon',
                variant: 'info',
              },
            });
          }
        } else {
          // Handle failure response from the server
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message || 'Error checking email. Please try again later.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error checking user existence via server:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error checking email. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Step 2: Handle user authentication
    async handleAuth() {
      if (this.isSignup) {
        // If it's a signup, call the handleSignup method
        await this.handleSignup();
      } else {
        // If it's a login, call the handleLogin method
        await this.handleLogin();
      }
    },

    // Handle User Signup
    async handleSignup() {
      // console.log('Handling signup...');
      // Ensure the password meets the minimum requirements
      if (this.password.length < 6) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Password must be at least 6 characters long.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Passwords do not match.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        return;
      }

      try {
        // console.log('Creating user in Firebase...');
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        await updateProfile(userCredential.user, { displayName: this.userName });

        // Save the user data to Cosmos DB or your database
        const userData = {
          userId: userCredential.user.uid,
          email: this.email,
          userName: this.userName,
          photoURL: userCredential.user.photoURL || 'default_photo_url',
          userRole: 'user',
          account: 'free', // Default value for new users
          status: 'active', // Default status for new users
          plan: 'starter', // Default plan for new users
          loginMethod: 'email', // Save the login method
        };
        // console.log('userData to DB', userData);
        await this.saveUserToDB(userData);
      } catch (error) {
        console.error('Error during signup:', error);
        this.handleAuthError(error);
      }
    },

    // Handle User Login
    async handleLogin() {
      console.log('Handling login...');
      try {
        if (!this.password) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please enter your password.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          return;
        }
        // console.log('Signing in user in Firebase...');
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        await this.fetchUserFromDB(userCredential.user.uid);
      } catch (error) {
        console.error('Error during login:', error);
        this.handleAuthError(error);
      }
    },

    // Fetch the user data from Cosmos DB
    async fetchUserFromDB(userId) {
      try {
        // console.log('Fetching user from Cosmos DB...');
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getUser/${userId}`);
        const data = await response.json();

        if (data.success) {
          // console.log('User fetched successfully:', data.data);
          this.handleSuccessfulAuth(data.data);
        } else {
          console.log('User not found in DB.');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User not found in the database.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error fetching user from DB:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Save the new user to Cosmos DB
    async saveUserToDB(userData) {
      try {
        // console.log('Saving user to Cosmos DB...');
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/saveUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });

        const data = await response.json();

        if (data.success) {
          this.handleSuccessfulAuth(data.data);
        } else if (data.errorCode === 'DUPLICATE_ERROR') {
          // console.log('Duplicate user found, fetching user data...');
          await this.fetchUserFromDB(userData.userId);
        } else {
          console.error('Error saving user to DB:', data.message);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message || 'Error saving user to database.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (error) {
        console.error('Error saving user to DB:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving user to database. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    // Handle successful authentication
    handleSuccessfulAuth(userData) {
      // console.log('Handling successful authentication...');
      // Dispatch the 'login' action with userData
      this.$store.dispatch('app/login', userData);

      // Display success toast
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Welcome back, ${userData.userName}!`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      });

      // Redirect to the home page or desired route
      // this.$router.push({ name: 'home' });

      // Hide login modal
      this.$bvModal.hide('modal-login');
    },

    // Handle authentication errors with user-friendly messages
    handleAuthError(error) {
      const errorMessages = {
        'auth/invalid-email': 'The email address is not valid.',
        'auth/user-disabled': 'The user account has been disabled.',
        'auth/user-not-found': 'No user found with this email address.',
        'auth/wrong-password': 'The password is incorrect.',
        'auth/email-already-in-use': 'The email address is already in use by another account.',
        'auth/weak-password': 'The password is too weak.',
        'auth/invalid-credential': 'The credentials are not valid.',
        // Add more error codes and messages as needed
      };

      const message = errorMessages[error.code] || 'An unknown error occurred. Please try again.';
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    },

      // Reset form data when modal is closed
      resetForm() {
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.userName = '';
      this.isSignup = false;
      this.showPasswordField = false;
      this.showAdditionalFields = false;
      this.socialProviderUsed = '';
    },
  },
};
</script>
<style scoped>
.login-signup {
  color: #333;
}

.main-heading h4 {
  font-size: 1.5rem;
  text-align: center;
}

.social-login-buttons {
  margin-bottom: 15px;
}

.text-center h5 {
  font-weight: bold;
}

.agreement small {
  color: #666;
}

.agreement a {
  color: #007BFF;
  text-decoration: none;
}

.agreement a:hover {
  text-decoration: underline;
}
</style>

